/* header */
.main-header{
    margin: 0 !important;
    margin-bottom: 20px !important;
    /* background-color: transparent !important; */
    z-index: 999;
    color: #000 !important;
    padding: 15px 30px !important;
    border: none;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
}
.navbar_logo{
    display: flex;
    align-items: center;
}
.navbar_logo img{
    border-radius: 10px;
}
.navbar_logo h2{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 10px;
} 
.admin-btn{
    background-color: #d62527;
    color: #fff;
    font-weight: 700;
    padding: 5px 40px;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
}
.admin-btn:hover{
    background-color: transparent;
    border:1px solid #d62527;
    color:#d62527;
}
.logout_btn{
    border-bottom: 2px solid #d62527;
    border-radius: 0;
    margin-right: 15px;
}
.no_img{
    mix-blend-mode: darken
}

/* sidebar */
.main-sidebar{
    position: fixed !important;
    top: 100px !important;
    height: 100vh !important;
    background-color: #fff !important;
    box-shadow: none !important;
    border-radius: 0 35px 0 0 !important;
    width: 250px !important;
}
.main-sidebar ul li a{
    color: #d62527 !important;
    font-weight: 700;
}
.dropdown-menu{
    position: inherit !important;
    display: block !important;
    padding: 0;
    padding-left: 30px !important;
    width: 100%;
    color: #000 !important;
    border: none;
    background: none;
    box-shadow: none;
}
.open .dropdown-menu .nav-item .nav-link {
    color: #000 !important;
}
.dropdown-menu ul li a {
    color: #000 !important;
    font-weight: 400 !important;
}
.main-sidebar  .nav-sidebar  .nav-item  .nav-link.active {
    background-color: transparent;
    box-shadow: none;
    border-left: 5px solid #d83234;
    border-radius: 0;
}
.dropdown-submenu{
    list-style: none;
}
/* main_page */
.content-wrapper{
    margin-left: 270px !important;
}