.content-wrapper{
    margin-left: 270px !important;
    /* height:100vh; */
    background-color: transparent !important;
    border-radius: 20px;
    margin-right: 100px;
    margin-top: 100px;
}

.fillter_part{
    background-color: #fff;
    padding: 10px 30px;
    border-radius: 40px;
    margin-bottom: 30px;
}

.fillter_part .fillter_box .form-group{
    margin: 0;
    position: relative;
}

.fillter_part .fillter_box .form-group .form-select{
    background-image: none;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #bfbfbf;
    padding: 0;
    height: 40px;
}

.bulk_btn{
    text-decoration: none;
    color: #d83234;
}

.fillter_part .fillter_box .form-group .form-select:focus{
    box-shadow: none;
}

.fillter_part .fillter_box .form-group span.icon-box{
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #d62527;
    color: #fff;
    font-size: 14px;
    top: 50%;
    right: 0px;
    transform: translate(0 , -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.fillter_part .fillter_box .form-group span .icon{
    width: 13px;
    height: 13px;
}

.fillter_part .fillter_box h3{
    font-size: 20px;
    margin: 0;
}

.over_project,.summary_acivity{
    padding: 15px 30px;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 40px;
}

.over_project h2,.summary_acivity h2,.monthly_distribute h2,.sitewise_activity h2{
    font-size: 20px;
    margin-bottom: 15px;
}

.project_box_main{
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    column-gap: 20px;
}
.impact_box{
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    column-gap: 20px;
}
.unit_analysis{
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    column-gap: 20px;
}
.survey_graph_data{
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    column-gap: 20px;
}

.project_box_main .project_box,.impact_box .project_box,.village_detail_inr .village_detail_box,.field_activity_inr_bottom .field_activity_box,.overall_info_main .overall_info_box{
    background-color: #eef0f4;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.project_box_main .project_box h3,.overall_info_main .overall_info_box h3,.impact_box .project_box h3,.village_detail_inr .village_detail_box h3,.field_activity_inr_bottom .field_activity_box h3{

    font-size: 60px;
    color: #00bf63;
    margin-bottom: 15px;    
    align-items: center;
}

.project_box_main .project_box p,.overall_info_main .overall_info_box p, .impact_box .project_box p,.village_detail_inr .village_detail_box p,.field_activity_inr_bottom .field_activity_box p,.survey_graph_data p,.siteWise_graph p{
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
    text-align: center;
    font-weight: 700;
}

.project_box .project_box_icon{
    position: absolute;
    top: 5px;
    left: 5px;
    color: #f4c01e;
    font-size: 22px;
}

.summary_acivity p,.monthly_distribute p,.over_project p,
.location_map p{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
    margin-top: 15px;
    text-align: center;
}

.react-datepicker-wrapper{
    width: 100% !important;
}
.recharts-wrapper{
    width: 100% !important;
}
.recharts-wrapper .recharts-surface{
    width: 100%;
}
.activity_map iframe{
    /* height: 350px !important; */
    height: calc(100% - 50px);
}

.monthly_distribute{
    background-color: #fff;
    padding: 10px 30px;
    border-radius: 40px;
    margin-bottom: 30px;
}

.location_map iframe{
    height: 250px !important;
}

.village_detail_main{
    margin-bottom: 30px;
}

.village_detail_main .location_map,.village_detail_main .village_detail_inr,.data_table,.field_main .field_activity,.survey_data,.sitewise_activity,.overall_inventory .overall_inventory_graph,.overall_main .overall_info_main{
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
}
.overall_inventory {
    margin-bottom: 30px
}
.village_detail_main .village_detail_inr,.village_detail_main .data_table,.overall_inventory .data_table,.village_detail_main .location_map{
    margin-bottom: 0 !important;
}

.village_detail_main .village_detail_box_main{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    column-gap: 20px;
}

.village_detail_main .location_map h2,.overall_info_main h2,.village_detail_main h2,.field_main .field_activity h2,.survey_data h2,.overall_inventory_graph h2{
    font-size: 20px;
    margin-bottom: 15px;
}

.village_detail_main .location_map iframe{
    height: 170px !important;
}

.overall_inventory .data_table_header{
    align-items: baseline;
}

.data_table_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.data_table_header h2{
    font-size: 20px;
    margin: 0;
}

.data_table_header span.csv_bt{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d62527;
    color: #fff;
    border-radius: 50%;
    margin-left: 15px;
}
.data_table_header .csv_bt a {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
}

.scroll_table{
    height: 300px;
    overflow-y: auto;
}
.order_table{
    height: 350px;
    overflow-y: auto;
}
.scroll_table td{
    white-space: nowrap;
    
}
.field_scroll{
    height: 200px;
    overflow-y: auto;
}

.field_activity_inr{
    display: grid;
    grid-template-columns: repeat(6 , 1fr);
    column-gap: 20px;
    margin-bottom: 20px;
}

.field_activity_box{
    background-color: #eef0f4;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.field_activity_box h3{
    font-size: 30px;
    margin-bottom: 15px;
    color: #00bf63;
}

.field_activity_box p{
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
    text-align: center;
    font-weight: 500;
}

.field_activity_box .icon{
    position: absolute;
    top: 5px;
    left: 5px;
    color: #00bf63;
    font-size: 22px;
}

.field_activity_inr_bottom{
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    column-gap: 30px;
}

.formreport_main .village_detail_inr .village_detail_box h3{
    font-size: 45px;
}

.data_table .table thead th{
    border-top: 0;
    border-bottom-width: 1px;
}

.qna_data_table .table thead tr th{
    border-bottom: 0;
}

.qna_data_table .table tbody tr td:first-child{
    border-right: 2px solid #dee2e6;
}

.qna_data_table .table tbody tr td{
    border-top: 0;
}

.data_search{
    position: relative;
    width: 300px;
}

.filter_data_table .data_table_header{
    margin-bottom: 15px;
}

.data_search input.form-control,.react-autosuggest__input{
    border: none;
    border-bottom: 2px solid #b7b7b7;
    border-radius: 0;
    font-size: 14px;
    width: 100%;
    position: relative;
}

.react-autosuggest__input{
    padding: 7px 15px;
}

.data_search input.form-control:focus,.react-autosuggest__input:focus{
    box-shadow: none;
}



.react-autosuggest__input:focus-visible {
    border: none;
}

.data_search .react-autosuggest__suggestions-list{
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    top:100%;
    left: 0;
    position: absolute;
    background-color: #fff;
    padding:20px 10px;
    box-shadow: 0 2px 10px rgb(212 37 39 / 30%);
    border-radius: 10px;
}

.data_search .react-autosuggest__suggestions-list li{
    padding: 5px;
}

.data_search .react-autosuggest__suggestions-list li:hover{
    background-color: #f3c5c6;
    color: #fff;
    cursor: pointer;
}

.data_search .icon{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0 , -50%);
}

.user_info ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.user_info ul li{
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.user_info ul li .icon{
    color: #d62527;
    margin-right: 10px;
}

.user_info ul li label{
    font-size: 16px;
    margin: 0;
}

.download_btn .btn{
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    color: #000;
    background-color: #f3c5c6;
}

.status_part ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.status_part ul li{
    margin: 0 5px;
}

.status_part ul li label{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.status_part ul li label.active{
    background-color: #00bf63;
}

.status_part ul li label.inactive{
    background-color: #737373;
}


.overall_inventory .data_table .table thead th{
    border: none;
    padding:10px 10px 30px;
    text-align: center;
    vertical-align: middle;
}

.overall_inventory .data_table .table tbody td{
    border-bottom: 0;
    padding:15px 10px;
    text-align: center;
    vertical-align: middle;
}

/* custom check box */

.tb_check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .tb_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50% , -50%);
  }

  .tb_check:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .tb_check input:checked ~ .checkmark {
    background-color: #d9d9d9;
  }
  
  .check_icon {
    display: none;
  }
 
  .tb_check input:checked ~ .checkmark .check_icon {
    display: block;
  }
  
  .tb_check .check_icon{
    color: #00bf63;
    font-size: 20px;
  }

  .action_bt{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .action_bt a{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .action_bt .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .order_inventory .data_table th{
    padding: 10px 20px 30px;
  }

  .order_inventory .data_table td{
    padding: 15px 10px;
  }

  .order_inventory .data_table th,.order_inventory .data_table td{
    text-align: center;
    vertical-align: middle;
    border: none;
  }

  .order_data_table_bt .add_bt{
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 20px;
    color: #000;
    background-color: #f3c5c6;
    margin-right: 15px;
  }
.form_intent select,.form_intent .form-control,.form_intent .form_Datepicker{
    background-color: #d9d9d9;
    border: none;
    border-radius: 20px;
}
.form_intent button,.form_intent button:hover{
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    padding: 10px 30px;
    border: none;
    background-color: #d42527;
}
.recharts-tooltip-wrapper.recharts-tooltip-wrapper-right.recharts-tooltip-wrapper-top {
    z-index: 1;
}

.table_filter h3{
    font-size: 22px;
    margin: 0;
}

.table_filter{
    display: flex;
    align-items: center;
}

.table_filter button.badge{
    background-color: #adafb2;
    border: none;
    padding: 5px 15px;
    color: #fff !important;
    font-size: 15px;
    margin: 0 10px;
}
.table_filter button.badge.active,.table_filter button.badge:hover{
    background-color: #f3c5c6;
}
.overall_table{
    height: 330px;
    overflow-y: auto;
    overflow-x: hidden;
}
.css-13cymwt-control  {
    border: none !important;
    border-bottom: 2px solid #bfbfbf !important;
    border-radius: 0 !important;
}